<template>
  <div class="import-detail">
    <div class="detail-cont" :style="selectArr.val.length > 0 ? 'padding-bottom:146px' : ''">
      <div class="detail-top">
        <div class="files">
          <span class="files-name">{{ importDetail.val.filename }}</span>
          <a-divider direction="vertical" />
          <span class="files-time">Imported At: {{ importDetail.val.imported_at }}</span>
        </div>
        <div>
          <a-space>
            <a-button shape="round">
              <a style="text-decoration:none;"
                :href="adslink + importDetail.val.upload_filename_url + '?' + Math.random()"
                :download="importDetail.val.filename">
                Download File
              </a>
            </a-button>
            <a-button shape="round" status="danger" :disabled="importDetail.val.error === 0">
              <a style="text-decoration:none;" :href="adslink + importDetail.val.error_filename_url + '?' + Math.random()"
                :download="importDetail.val.error_filename" v-if="importDetail.val.error !== 0">
                Error Orders
              </a>
              <span v-else>Error Orders</span>
            </a-button>
          </a-space>
        </div>
      </div>
      <div class="table-data">
        <!-- 导航 -->
        <div class="table-top">
          <div class="til">Import Order</div>
          <div class="menu-select">
            <a-menu mode="horizontal" @menu-item-click="clickMenu" :selected-keys="navselect">
              <a-menu-item :key="val" v-for="val, k in navList">{{ k }}</a-menu-item>
            </a-menu>
          </div>
        </div>
        <div class="count">
          <div>
            <span>Total</span>
            <span>{{ importDetail.val.total }}</span>
          </div>
          <div>
            <span>Imported</span>
            <span>{{ importDetail.val.created }}</span>
          </div>
          <div>
            <span class="error">Error</span>
            <span class="error">{{ importDetail.val.error }}</span>
          </div>
        </div>
      </div>
      <div class="content-list">
        <!-- 表格 -->
        <a-config-provider :locale="enUS">
          <a-table :columns="listColumns" :data="listData.value" :bordered="false" :pagination="false"
            :scroll="{ y: tableHeight.value }" :loading="loading" :row-selection="listType === 100 ? null : setSelection"
            @select-all="changmsg" @select="selectVal" :selected-keys="selectArr.val">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 20 + 'px' }" class="empty-cont">
                <img src="../../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 20 + 'px' }" class="skeleton-box">
              </div>
            </template>
            <template #price="{ record }">
              <div v-if="record.price > 0">
                <a-tooltip position="right">
                  <a-tag>${{ toThousands(record.price) }}</a-tag>
                  <template #content>
                    <div v-for="val, k, i in record.quote_detail" :key="i">
                      <div>
                        <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                        <span v-else>-{{ k }}:</span>
                        <span> ${{ val }}</span>
                      </div>
                    </div>
                  </template>
                </a-tooltip>
              </div>
              <a-tag v-else>${{ toThousands(record.price) }}</a-tag>
            </template>
            <template #status="{ record }">
              <a-tag>{{ record.status }}</a-tag>
            </template>
            <template #trackingid="{ record }">
              <span v-if="record.trackingid">{{ record.trackingid }}</span>
              <span v-else>N/A</span>
            </template>
            <template #address="{ record }">
              <div>{{ record.street1 }}</div>
              <div>{{ record.street2 }}</div>
              <div>{{ record.city }} {{ record.state }} {{ record.zip }}
                {{ record.country }}</div>
            </template>
            <template #action="{ record }">
              <a-tooltip content="Edit">
                <a-button v-show="record.status_code === 1" shape="circle" @click="goedit(record.key)" class="m-r-8">
                  <icon-edit />
                </a-button>
              </a-tooltip>
              <a-tooltip content="Label">
                <a-button
                  v-show="record.status_code === 2 || record.status_code === 3 || record.status_code === 4 || record.status_code === 5 || record.status_code === 7 || record.status_code === 8"
                  shape="circle" @click="preview(record.view)" class="m-r-8">
                  <icon-eye />
                </a-button>
              </a-tooltip>
              <a-tooltip content="Print">
                <a-button v-show="record.status_code === 2" type="primary" shape="circle"
                  @click="print(record.view, record.key)" class="m-r-8">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.5 0C2.94772 0 2.5 0.447715 2.5 1V3.5H1.5C0.6775 3.5 0 4.1775 0 5V9C0 9.55229 0.447715 10 1 10H2.5V11C2.5 11.5523 2.94772 12 3.5 12H8.5C9.05229 12 9.5 11.5523 9.5 11V10H11C11.5523 10 12 9.55229 12 9V5C12 4.1775 11.3225 3.5 10.5 3.5H9.5V1C9.5 0.447715 9.05228 0 8.5 0H3.5ZM3.5 1H8.5V3.5H3.5V1ZM1.5 4.5H10.5C10.783 4.5 11 4.717 11 5V9H9.5V7H2.5V9H1V5C1 4.717 1.217 4.5 1.5 4.5ZM2 5C1.725 5 1.5 5.225 1.5 5.5C1.5 5.775 1.725 6 2 6C2.275 6 2.5 5.775 2.5 5.5C2.5 5.225 2.275 5 2 5ZM3.5 8H8.5V11H3.5V8Z"
                      fill="white" />
                  </svg>
                </a-button>
              </a-tooltip>
              <a-tooltip content="Detail" v-if="(record.status_code !== 1)">
                <a-button @click="godetail(record.key)" shape="circle">
                  <icon-right />
                </a-button>
              </a-tooltip>
            </template>
          </a-table>
        </a-config-provider>
        <div class="page">
          <a-space>
            <span>Total : {{ orderTotal }} orders</span>
            <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
              <icon-left />
            </a-button>
            <span class="currentpage">{{ currentpage }}</span>
            <div>/</div>
            <div>{{ totalPages }}</div>
            <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
              <icon-right />
            </a-button>
            <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
              <a-option :value="20">20 / Page</a-option>
              <a-option :value="50">50 / Page</a-option>
              <a-option :value="100">100 / Page</a-option>
              <a-option :value="200">200 / Page</a-option>
            </a-select>
          </a-space>
        </div>
      </div>
    </div>
    <div class="addnew-footer" v-if="listType !== 100 && listType !== 7 && listType !== 8 && selectArr.val.length > 0">
      <div class="items">
        <a-space>
          <a-checkbox v-model="checkedValue" @change="changmsg"
            v-if="listType === 1 || listType === 2 || listType === 3 || listType === 4 || listType === 5" class="itemNum">
            {{ selectArr.val.length }} items
          </a-checkbox>
          <a-popconfirm content="Are you sure you want to delete?" type="warning" ok-text="Delete" cancel-text="Cancel"
            @ok="moreDelete" style="width: 276px;" class="deletePop">
            <a-button v-if="listType === 1 && isDisable === false" status="danger" size="large" shape="round">Delete
            </a-button>
          </a-popconfirm>
          <a-button v-if="listType === 2 && isDisable === false" type="primary" size="large" @click="morePrint"
            shape="round">Print
          </a-button>
          <a-button v-if="listType === 3 && isDisable === false" type="primary" size="large" @click="moreShipped"
            shape="round">Mark as
            Shipped</a-button>
          <a-popconfirm content="Are you sure you want to duplicate?" type="warning" ok-text="Duplicate"
            cancel-text="Back" @ok="moreDuplicate" class="warnPop">
            <span v-if="listType === 2 || listType === 3 || listType === 4 || listType === 5">
              <a-button size="large" v-if="isDisable === false" shape="round">
                Duplicate
              </a-button>
            </span>
          </a-popconfirm>
          <a-popconfirm content="Are you sure you want to create return order(s)?" type="warning" ok-text="Create"
            cancel-text="Back" @ok="moreReturn" class="warnPop">
            <span v-if="listType === 2 || listType === 3 || listType === 4 || listType === 5">
              <a-button shape="round" size="large" v-if="returnDisable === true">
                Create Return Order
              </a-button>
            </span>
          </a-popconfirm>
          <a-popconfirm content="Are you sure to create manifest?" type="warning" ok-text="Create" cancel-text="Cancel"
            @ok="manifestOk">
            <a-button v-if="listType === 3 && isDisable === false" type="outline" size="large" shape="round">Create
              a
              Manifest</a-button>
          </a-popconfirm>
          <a-popconfirm content="Are you sure to cancel selected order(s)?" type="warning" ok-text="Cancel Order(s)"
            cancel-text="Back" @ok="moreCancel" class="warnPop">
            <span v-if="listType === 2 || listType === 3 || listType === 4">
              <a-button status="warning" size="large" v-if="isDisable === false" shape="round">Cancel
              </a-button>
            </span>
          </a-popconfirm>
        </a-space>
      </div>
      <div v-if="listType === 1">
        <div class="quanty">
          <a-space>
            <a-tooltip position="right">
              <a-button :disabled="totalPayPrice === '0.00'">Est. Cost:</a-button>
              <template #content>
                <div v-for="val, k, i in payDetail" :key="i">
                  <div>
                    <span>{{ k }}:</span>
                    <span> {{ val }}</span>
                  </div>
                </div>
              </template>
            </a-tooltip>
            <div class="total-q-num">${{ totalPayPrice }}</div>
            <a-button shape="round" type="primary" @click="getPayLabel" :disabled="payDisable">Pay</a-button>
          </a-space>
        </div>
        <div class="pay-tip">
          By paying your order ,you agree to our<br />
          <span>company privacy </span>and <span>conditions of use.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { useRoute, useRouter } from 'vue-router'
import { ref, reactive, onMounted } from 'vue';
import { orderList, orderBatchHandle, markAsShipped, manifestAdd, printLabel, orderUploadDetail, getMultiOrderQuoteDetail } from '@/api/order.js'
import { toThousands } from '@/utils/handleNumber.js'
import { Notification, Button } from '@arco-design/web-vue'
import local from '@/utils/local'
import {
  IconRight, IconLeft, IconEdit, IconEye
} from '@arco-design/web-vue/es/icon'
// 使用loading
import { load } from '@/utils/loading.js'
// 使用pinia
import { mainStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'
const adslink = process.env.VUE_APP_ADSLINK
const setSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const loading = ref(false)
const store = mainStore()
const { goImport } = storeToRefs(store)
const router = useRouter()
const route = useRoute()
let uploadId = route.query.upload
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
tableHeight.value = document.body.clientHeight - 400
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableHeight.value = document.body.clientHeight - 400
  }
})
// 获取上传详情------------------------------------
const importDetail = reactive({ val: {} })
const getUploadDetail = async () => {
  const msg = await orderUploadDetail({
    upload_id: uploadId
  })
  if (msg.code === 0) {
    importDetail.val = msg.data
  }
}
getUploadDetail()
// 表格操作-------------------------------------------------
// 导航选中
let navselect = ref([100])
const listType = ref(100)
// 导航
const navList = {
  'All': 100, // eslint-disable-line quote-props
  'Awaiting Payment': 1,
  'Awaiting Shipment': 2,
  'Printed': 3, // eslint-disable-line quote-props
  'Shipped': 4, // eslint-disable-line quote-props
  'Canceled': 5, // eslint-disable-line quote-props
  'Called Back': 7,
  'Change Address': 8
}
// 默认
const noDefault = ref(null)
let selectArr = reactive({
  val: []
})
let selectPayArr = reactive({
  val: []
})
let isDisable = ref(true)
let payDisable = ref(true)
// regular表格配置
const listColumns = [
  {
    title: 'No.',
    dataIndex: 'no',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 50
  },
  {
    title: 'Status',
    dataIndex: 'status',
    slotName: 'status',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 160
  },
  {
    title: 'PO Number',
    dataIndex: 'ponumber',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 185
  },
  {
    title: 'Service',
    dataIndex: 'service',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Shipment',
    dataIndex: 'package',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 120
  },
  {
    title: 'Est. Cost',
    dataIndex: 'price',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.price) > parseFloat(b.price)) {
          sortVal = 1;
        } else if (parseFloat(a.price) < parseFloat(b.price)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    slotName: 'price',
    width: 150
  },
  {
    title: 'Sender',
    dataIndex: 'sender',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    width: 150
  }
]
// 表格数据
let listData = reactive({ value: [] })
const clickMenu = (val) => {
  selectArr.val = []
  const tableBody = document.querySelector('.arco-table-body')
  tableBody.scrollTop = 0
  listData.value = []
  noDefault.value = val
  navselect.value = [val]
  currentpage.value = 1
  listType.value = val
  getList()
}

// 当前页
const currentpage = ref(1)
// 总的order
const orderTotal = ref(0)
// 总页数
const totalPages = ref(0)
const pageSize = ref(20)
// 获取表格数据
const getList = async () => {
  loading.value = true
  const msg = await orderList({
    page: currentpage.value,
    status: listType.value,
    upload_id: uploadId,
    pageCount: pageSize.value
  })
  if (msg.code === 0) {
    loading.value = false
    store.goImport = null
    local.del('goimportdetail')
    orderTotal.value = msg.data.totalCount
    totalPages.value = msg.data.totalPages
    currentpage.value = msg.data.currentPage
    let info = msg.data.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({ key: info[i].id, no: i + 1 + (currentpage.value - 1) * 20, status: info[i].status_desc, ponumber: info[i].ponumber, service: info[i].service_type_desc, package: info[i].shipment_count, price: info[i].estimate_shipping_charge, sender: info[i].from_name, recipient: info[i].to_name, view: info[i].label_file_name, serviceType: info[i].service_type, quote_detail: info[i].quote_detail, status_code: info[i].status })
    }
    listData.value = currentData
    // local.set('importDetail', JSON.stringify({
    //   data: listData.value,
    //   navselect: navselect.value,
    //   listType: listType.value,
    //   orderTotal: orderTotal.value,
    //   totalPages: totalPages.value,
    //   currentpage: currentpage.value
    // }
    // ))
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
// 去编辑页面
const goedit = (info) => {
  let routerUrl = router.resolve({ path: '/order/editorder', query: { orderId: info, where: 'import', upload: uploadId } })
  window.open(routerUrl.href, '_blank')
}
// 去详情页面
const godetail = (msg) => {
  let routerUrl = router.resolve({ path: '/order/list/listdetail?orderId=', query: { orderId: msg } })
  window.open(routerUrl.href, '_blank')
}
// 预览:awaiting shipment
const preview = (url) => {
  window.open(url, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
// 打印:awaiting shipment
const print = async (url, printId) => {
  window.open(url, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
  const msg = await printLabel({
    order_id: printId
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Printed.'
    })
    listType.value = 3
    navselect.value = [3]
    currentpage.value = 1
    listData.value = []
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}

// 刷新页面------------------------------------
const goimportdetail = JSON.parse(local.get('goimportdetail'))
if (goImport.value) {
  listType.value = goImport.value
  navselect.value = [goImport.value]
  listData.value = []
  getList()
} else if (goimportdetail) {
  listType.value = goimportdetail
  navselect.value = [goimportdetail]
  listData.value = []
  getList()
} else {
  getList()
}
const checkedValue = ref(false)
// 批量操作选择------------------------------------------------------------
// 多选
const returnDisable = ref(false)
const changmsg = (checked) => {
  checkedValue.value = checked
  if (checked) {
    // cancel按钮
    selectArr.val = []
    listData.value.forEach((item) => {
      selectArr.val.push(item.key)
      isDisable.value = false
    })
    // pay按钮
    if (listData.value.some(val => val.serviceType === 0)) {
      selectPayArr.val = []
      payDisable.value = true
    } else {
      listData.value.forEach((item) => {
        selectPayArr.val.push(item.key)
      })
      payDisable.value = false
    }
    // pay总价和详情
    if (listType.value === 1) {
      getPayDetail()
    }
    // return 按钮
    returnDisable.value = listData.value.every(val => val.package === 1)
  } else {
    // cancel按钮
    selectArr.val = []
    isDisable.value = true
    // pay按钮
    selectPayArr.val = []
    payDisable.value = true
    // pay总价和详情
    totalPayPrice.value = '0.00'
    payDetail.value = {}
    // return 按钮
    returnDisable.value = false
  }
}
let noServiceArr = []
const totalPayPrice = ref('0.00')
const payDetail = ref({})
const estCost = ref(true)
const getPayDetail = async () => {
  const msg = await getMultiOrderQuoteDetail({
    order_ids: selectArr.val
  })
  if (msg.code === 0) {
    if (msg.data.estimate_shipping_charge > 0) {
      totalPayPrice.value = msg.data.estimate_shipping_charge
      payDetail.value = msg.data.detail
    } else {
      totalPayPrice.value = '0.00'
      payDetail.value = {}
    }
  }
}
// 单选
function selectVal (rowKeys, _, record) {
  // cancel按钮
  selectArr.val = rowKeys
  if (rowKeys.length === listData.value.length) {
    checkedValue.value = true
  } else {
    checkedValue.value = false
  }
  if (listType.value === 1 && rowKeys.length > 0) {
    getPayDetail()
  } else {
    totalPayPrice.value = '0.00'
    payDetail.value = {}
  }
  if (rowKeys.length > 0) {
    isDisable.value = false
  } else {
    isDisable.value = true
  }
  // pay按钮
  if (rowKeys.length > 0) {
    if (record.serviceType === 0) {
      noServiceArr.push(record.key)
    }
    // 比较rowKeys和noServiceArr，看看noServiceArr是否有值在rowKeys里面
    for (let i = 0; i < noServiceArr.length; i++) {
      for (let j = 0; j < rowKeys.length; j++) {
        if (noServiceArr[i] === rowKeys[j]) {
          payDisable.value = true
          return false
        }
      }
    }
    selectPayArr.val = rowKeys
    payDisable.value = false
  } else {
    selectPayArr.val = []
    payDisable.value = true
  }
  // return 按钮
  if (rowKeys.length > 0) {
    let returnArr = []
    for (let i = 0; i < rowKeys.length; i++) {
      for (let j = 0; j < listData.value.length; j++) {
        if (rowKeys[i] === listData.value[j].key) {
          returnArr.push(listData.value[j].package)
        }
      }
    }
    returnDisable.value = returnArr.every(val => val === 1)
  } else {
    returnDisable.value = false
  }
}
// 批量getLabel: awaiting payment
async function getPayLabel () {
  load.show('Pay & Getting labels...')
  noServiceArr.value = []
  const msg = await orderBatchHandle({
    order_ids: selectPayArr.val,
    handle_type: 2
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Paid & Get labes.'
    })
    selectPayArr.val = []
    selectArr.val = []
    listType.value = 2
    navselect.value = [2]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payImportdetail',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification}>
        Recharge
      </Button>
    })
  }
}
const closeNotification = () => {
  Notification.remove('payImportdetail')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 批量删除: awaiting payment
async function moreDelete () {
  load.show('Deleting...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 4
  })
  if (msg.code === 0) {
    load.hide()
    selectArr.val = []
    Notification.success({
      title: 'Success',
      content: 'Deleted.'
    })
    listType.value = 1
    navselect.value = [1]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量打印：awaiting shipment
async function morePrint () {
  load.show('Printing...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 3
  })
  if (msg.code === 0) {
    window.open(adslink + msg.data.labelPath, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
    selectArr.val = []
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been printed.'
    })
    listType.value = 3
    navselect.value = [3]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量复制：awaiting shipment
async function moreDuplicate () {
  load.show('Duplicating...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 5
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Duplicated.'
    })
    router.push('/order/list/orderlist')
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: 'Failed to Copy, please try again.'
    })
  }
}
// 批量return:awaiting shipment
async function moreReturn () {
  load.show('Creating return Order(s)...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 6
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Return label(s) has/ have been created.'
    })
    router.push('/order/list/orderlist')
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量取消：awaiting shipment
async function moreCancel () {
  load.show('Canceling the order(s) ...')
  const msg = await orderBatchHandle({
    order_ids: selectArr.val,
    handle_type: 4
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order has been canceled.'
    })
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量 Mark as Shipped:printed
async function moreShipped () {
  load.show('Loading...')
  const msg = await markAsShipped({
    order_ids: selectArr.val
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The order(s) has/have been marked as shipped.'
    })
    listType.value = 4
    navselect.value = [4]
    currentpage.value = 1
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 创建createManifest
const manifestOk = async () => {
  load.show('Creating Manifest...')
  const msg = await manifestAdd({
    order_ids: selectArr.val
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Manifest has/ have been created.'
    })
    getList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
</script>

<style scoped lang="less">
.detail-cont {
  margin: 0 24px;
}

.page {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.detail-top {
  margin: 18px 24px 20px 24px;
  display: flex;
  justify-content: space-between;

  .files-name {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
  }

  .files-time {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }
}

.table-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.table-top {
  display: flex;
  align-items: center;
}

.count {
  width: 235px;
  border: 1px solid var(--color-border-2);
  border-radius: 4px;

  div {
    height: 26px;
    line-height: 26px;

    span:first-child {
      display: inline-block;
      padding-left: 20px;
      width: 120px;
      border-right: 1px solid var(--color-border-2);
      background: var(--color-fill-1);
      color: var(--color-text-3);
      font-weight: 500;
      font-size: 12px;
    }

    span:nth-child(2) {
      padding-left: 20px;
      font-weight: 400;
      font-size: 12px;
      color: var(--color-text-1);
    }
  }
}

.error {
  color: rgb(var(--danger-6)) !important;
}

.count div:not(:last-child) {
  border-bottom: 1px solid var(--color-border-2);
}

.menu-select {
  width: 1010px;

  :deep(.arco-menu-light .arco-menu-item.arco-menu-selected) {
    background-color: var(--color-fill-2);
  }

  :deep(.arco-menu-selected-label) {
    background-color: transparent;
  }
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 48px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
}

.items {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-1);
  margin-right: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quanty {
  padding-bottom: 13px;

  .total-q-til {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }

  .total-q-num {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-text-1);
    padding-right: 6px;
  }
}

.pay-tip {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-text-3);
  line-height: 22px;

  span {
    font-weight: 700;
    font-size: 14px;
    color: rgb(var(--primary-6));
  }
}

.status {
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  padding: 1px 8px;
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}

.m-r-8 {
  margin-right: 8px;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
