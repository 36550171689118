import { defineStore } from 'pinia'
// 引入api中的接口
import { userInfo } from '@/api/account.js'
export const mainStore = defineStore('main', {
  state: () => {
    return {
      iconVisible: false,
      recordVisible: false,
      Golist: null,
      goImport: null,
      importData: [],
      userMsg: {}
    }
  },
  getters: {
  },
  actions: {
    // 获取用户信息
    async getUserInfo () {
      const msg = await userInfo({})
      if (msg.code === 0) {
        this.userMsg = msg.data
      }
    }
  }
})
